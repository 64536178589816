.MuiButton-root {
  position: relative;
  transition: all 0.3s ease;
  opacity: 0.85;
  cursor: default;
  padding: 6px 16px;
}

.MuiButton-root::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background: linear-gradient(90deg, transparent, #f50057, transparent);
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.MuiButton-root:hover {
  opacity: 1;
  color: #fff;
}

.MuiButton-root:hover::after {
  width: 80%;
}

/* Add subtle animation for the nav items */
.MuiToolbar-root .MuiButton-root {
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

.MuiToolbar-root .MuiButton-root:nth-child(2) { animation-delay: 0.1s; }
.MuiToolbar-root .MuiButton-root:nth-child(3) { animation-delay: 0.2s; }
.MuiToolbar-root .MuiButton-root:nth-child(4) { animation-delay: 0.3s; }
.MuiToolbar-root .MuiButton-root:nth-child(5) { animation-delay: 0.4s; }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 0.85;
    transform: translateY(0);
  }
}

.glass-nav {
  background: rgba(18, 18, 18, 0.7) !important;
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease-in-out;
}


.brand-text {
  font-family: 'Inter', sans-serif;
  display: flex;
  gap: 0.25rem;
  align-items: baseline;
  position: relative;
  padding: 1rem 0;
}

.text-gradient {
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 2.5rem;
  letter-spacing: -0.05em;
  animation: gradientFlow 8s ease infinite;
}

.text-thin {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 200;
  font-size: 2rem;
  letter-spacing: -0.03em;
}

/* Gradient animation */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Entrance animation */
.brand-text {
  animation: slideIn 0.8s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}



/* Mobile adjustments */
@media (max-width: 768px) {
  .text-gradient {
    font-size: 2rem;
  }
  
  .text-thin {
    font-size: 1.6rem;
  }
} 