.contact-section {
  min-height: 45vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    rgba(10, 10, 10, 0.95),
    rgba(25, 25, 25, 0.95)
  );
  position: relative;
  overflow: hidden;
}

.contact-content {
  text-align: center;
  padding: 3rem 2rem;
  position: relative;
  z-index: 2;
}

.contact-title {
  font-size: clamp(2rem, 4vw, 3rem) !important;
  font-weight: 700 !important;
  margin-bottom: 2rem !important;
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: fadeInUp 0.8s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.email-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.03);
  padding: 1.2rem 1.8rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.8s cubic-bezier(0.22, 1, 0.36, 1) 0.2s forwards;
}

.email-container:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.email-icon {
  font-size: 1.8rem !important;
  color: #4ECDC4;
}

.email-text {
  color: #fff;
  font-size: clamp(1.2rem, 3vw, 1.8rem) !important;
  font-weight: 400 !important;
  letter-spacing: 0.02em;
}

.copy-button {
  color: rgba(255, 255, 255, 0.6) !important;
  transition: all 0.3s ease !important;
}

.copy-button:hover {
  color: #4ECDC4 !important;
  background: rgba(78, 205, 196, 0.1) !important;
}

.decorative-line {
  width: 150px;
  height: 2px;
  background: linear-gradient(90deg, 
    transparent,
    rgba(78, 205, 196, 0.5),
    transparent
  );
  margin: 2rem auto;
  opacity: 0;
  animation: fadeIn 1s ease 0.4s forwards;
}

.copy-notification {
  .MuiSnackbarContent-root {
    background: rgba(78, 205, 196, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    font-weight: 500;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 