.hero {
  min-height: 80vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #fff;
  transition: transform 0.3s ease-out;
  background: #0a0a0a;
}

.hero-background {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, 
              rgba(25, 25, 25, 0.8) 0%,
              rgba(10, 10, 10, 0.95) 100%);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 2rem;
}

.hero-title {
  font-size: clamp(2.5rem, 7vw, 5rem) !important;
  font-weight: 800 !important;
  line-height: 1.1 !important;
  margin-bottom: 1.5rem !important;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}

.gradient-text {
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}

.highlight-text {
  background: linear-gradient(135deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline-block;
}

.hero-subtitle {
  font-size: clamp(1.2rem, 3vw, 1.8rem) !important;
  font-weight: 300 !important;
  opacity: 0.9;
  margin-bottom: 2rem !important;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 1s cubic-bezier(0.22, 1, 0.36, 1) 0.3s forwards;
}

.floating-orb {
  position: absolute;
  border-radius: 50%;
  filter: blur(60px);
  opacity: 0.5;
  animation: float 20s infinite;
}

.orb-1 {
  width: 250px;
  height: 250px;
  background: rgba(255, 107, 107, 0.15);
  top: -150px;
  right: -150px;
  animation-delay: -5s;
}

.orb-2 {
  width: 300px;
  height: 300px;
  background: rgba(78, 205, 196, 0.15);
  bottom: -200px;
  left: -200px;
  animation-delay: -10s;
}

.orb-3 {
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.1);
  top: 50%;
  right: 10%;
  animation-delay: -15s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5%, 5%);
  }
  50% {
    transform: translate(-5%, 8%);
  }
  75% {
    transform: translate(3%, -5%);
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: clamp(2.5rem, 6vw, 4rem) !important;
  }
  
  .hero-subtitle {
    font-size: clamp(1rem, 2.5vw, 1.4rem) !important;
  }
  
  .floating-orb {
    filter: blur(40px);
  }
} 